<template>
  <v-container fluid style="min-height: calc(60vh); align-items: center; justify-items: center; display: flex;">
    <v-row>
      <v-col cols="12" class="d-flex flex-column align-center">
        <v-img max-width="100" alt="dealpartner" src="/images/Icon.png" />

        <div class="primary--text text-h5">dealpartner.lk</div>
 
        <v-sheet max-width="500" class="text-center text-caption" color="transparent">
          Welcome to the <span class="primary--text">dealPartner.lk</span> Admin
          Dashboard! Here, you can manage deals, monitor performance, and
          optimize our platform. Use the tools at your disposal to keep
          everything running smoothly and ensure the best experience for our
          users. Your work is essential in maintaining the quality and success
          of <span class="primary--text">dealPartner.lk</span>.
        </v-sheet>
        <div class="secondary--text my-5">
          <v-icon color="secondary" small>mdi-tools</v-icon>
          app under construction
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    // Calendar: () =>
    //   import(
    //     /* webpackChunkName: "component-calendar" */ "@/components/Calendar"
    //   ),
  },
  computed: mapState({
    //
  }),
  props: [
    //
  ],
  data: () => ({
    breadcrumbsItems: [
      {
        text: "Dashboard",
        disabled: true,
        href: "",
      },
    ],
  }),
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>